<template>
  <div class="signUp p_lr_05">
    <div class="flex algin m_tb_04 p_in_04">
      <div
          class="p_in_02 m_r_10 txt_light pointer"
          :class="isActive == 0 ? 'sty3':''"
          @click="tab(0)"
      >
        全部
      </div>
      <div class="p_in_02 m_r_10 txt_light pointer" :class="isActive == 1 ?'sty3':''" @click="tab(1)">未开播</div>
      <div class="p_in_02 m_r_10 txt_light pointer" :class="isActive == 2 ?'sty3':''" @click="tab(2)">直播中</div>
      <div class="p_in_02 txt_light pointer" :class="isActive == 3 ?'sty3':''" @click="tab(3)">已结束</div>
    </div>
    <div v-loading="loading">
      <div v-if="courseList.length>0">
        <div class="box" style="margin-bottom: 40px">
          <div v-for="(item,index) in courseList" class="card pointer" :key="index" @click="jump(item)">
            <div class="img-box">
              <el-image
                  class="image"
                  :src="item.coverUrl"
              ></el-image>
            </div>
            <div class="flex flex_col p_in_04">
              <div class="flex p_in_02 algin w100">
                <div class="flex1 txt_light" style="text-align: left">{{ item.courseStartTime }}</div>
                <div class=" flex algin txt_right">
                  <div class="p_in_04 raduis f20 p_lr_05"
                       style="padding-top: 0.6em;padding-bottom: 0.6em"
                       :class="item.beginState == 1?'sty2':(item.beginState == 0?'sty1':'sty')">
                    {{ item.beginState == '1' ? '已结束' : (item.beginState == 0 ? '直播中' : '未开播') }}
                  </div>
                </div>
              </div>
              <div class="flex p_in_02 txt_light hidden">{{ item.name }}</div>
            </div>
          </div>
        </div>

        <div class="flex" style="width: 100%;justify-content: center;align-items: center">
          <el-pagination
              @current-change="changePage"
              background
              :page-size="pageSize"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="total"
              hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <el-empty v-else description="暂无我的报名课程"></el-empty>
    </div>
  </div>

</template>

<script>

import {mySubscribeCourse_api} from "@/api/mine";
import {mapState} from "vuex";
import {sessionGet, sessionSet} from "@/utils/local";
import defaultImg from '@/assets/image/default.png'

export default {
  name: "signUp",
  data() {
    return {
      isActive: 0,
      courseList: [],//我的报名课程
      currentPage: 1, // 当前页码
      pageSize: 8, // 每页条数
      total: 0, // 总条数
      loading: false,
      type: 0,//全部，（0 全部 1未开播 2直播中 3已结束）
      defaultImg
    };
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
    }),
  },
  methods: {
    //切换页码
    changePage(currentPage) {
      this.currentPage = currentPage
      // 我的报名课程
      this.getList()
    },
    // tab切换
    tab(num) {
      this.isActive = num
      this.type = num
      this.getList()
    },
    //跳转详情/直播
    jump(item) {
      /*if (item.beginState == 0) {
        sessionSet('details', item)
        this.$router.push('/liveStu/player?telecastId=' + item.id)
      } else if () {
      } else {
        this.$router.push('/order?courseId=' + item.id)
      }*/
      // switch (item.beginState) {
      //   case '0':
      //     sessionSet('details', item)
      //     this.$router.push('/liveStu/player?telecastId=' + item.id)
      //     break
      //   case '1':
      //     this.$router.push({path: '/courseDetail', query: {courseId: item.resourcesId}})
      //     break
      //   case '2':
      //     this.$router.push('/order?courseId=' + item.id)
      //     break
      // }
      console.log(item)

      // if(item.isLubo==0){
      //   console.log("22")
      // }
      let userInfo = sessionGet('userInfo')
      /* 不需要报名*/
      if (item.isSubscribe == 0) {
        sessionSet('details', item)
        //直播录播的视频跳转到相应页面
        if (userInfo && (item.beginState == 0)) {
          sessionSet('details', item)
          if (item.isLubo == 0) {
            this.$router.push('/liveStu/playerLubo?telecastId=' + item.id)
          } else {
            this.$router.push('/liveStu/player?telecastId=' + item.id)
          }
        } else {
          this.$router.push('/order?courseId=' + item.id)
        }
      } else {/* 需要報名 */
        if (userInfo && (item.beginState == 0)) {
          sessionSet('details', item)
          if (item.isLubo == 0) {
            this.$router.push('/liveStu/playerLubo?telecastId=' + item.id)
          } else {
            this.$router.push('/liveStu/player?telecastId=' + item.id)
          }
        } else {
          this.$router.push('/order?courseId=' + item.id)
        }
      }
    },

    // 获取我的报名课程
    async getList() {
      this.loading = true
      let res = await mySubscribeCourse_api({
        current: this.currentPage,
        size: this.pageSize,
        type: this.type,
      }).then(res => {
        this.loading = false
        return res
      })

      if (res.code === 200) {
        this.total = res.data.total
        this.courseList = res.data.records
      }
    }
  }
}
</script>
<style scoped>
.w100 {
  width: 100%;
}

.signUp {
  padding: 0.5em;
  box-sizing: border-box;
  background: #fff;
}

.flex {
  display: flex
}

.flex1 {
  flex: 1;
}

.algin {
  align-items: center
}

.txt_right {
  text-align: right
}

.raduis {
  border-radius: 10px;
}

.flex_col {
  flex-direction: column;
}

.p_in_04 {
  padding: 0.5em 0.5em;
  box-sizing: border-box
}

.p_in_02 {
  padding: 0.3em;
  box-sizing: border-box;
}

.img-box {
  width: 100%;
  height: 70%;
}

.image {
  display: block;
  width: 100%;
  height: 15vh;
}

.card {
  width: 22%;
  box-shadow: 1px 1px 12px #eee;
  margin: 1.2em 1.4em;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.scang {
  display: flex;
  width: 22px;
  height: 22px;
}

.txt_light {
  color: #666;
  font-size: 1.5rem
}

.m_lr_05 {
  margin: 0 0.5em;
}

.m_tb_04 {
  margin: 0.4em 0;
}

.sty {
  background: #ff5722;
  color: #fff;
}

.sty1 {
  background: #ffc107;
  color: #fff;
}

.sty2 {
  background: #9e9e9e;
  color: #fff;
}

.sty3 {
  /* background : #009688; */
  color: #3888F7;
}

.f20 {
  font-size: 0.8em;
  padding: 0.2em 0.4em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center
}

.p_lr_05 {
  padding-right: 1.5em;
  padding-left: 1.5em;
  box-sizing: border-box;
}

.hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
</style>
